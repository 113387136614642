import { snackVar } from "../constants/snackVar";
import { API_URL } from "../constants/urls";

const useTokenUpdate = () => {
    const tokenUpdate = async () => {
        const res = await fetch(`${API_URL}/auth/token/update`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!res.ok) {
            return new Error(res.statusText);
        }
        snackVar({ message: '토큰이 업데이트 되었습니다.', type: 'success' })
    }
    return { tokenUpdate }
}


export { useTokenUpdate } 