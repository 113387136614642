import { Box, Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

// interface ChatFileViewerProps {
//   imageUrl: string | undefined;
// }

interface ChatFileViewerProps2 {
  imagePaths: string[];
}
const ChatFileViewerMobile = ({ imagePaths }: ChatFileViewerProps2) => {
//const ChatFileViewerMobile = ({imageUrl}: ChatFileViewerProps) => {
  return (
    <>
      {/* PdfViewer */} 
      <Stack sx={{width: '100%', borderRight: '1px solid black' }}>
          <Box sx={{ height: '100vh', width: '100%', overflow: 'auto' }}>
          {/* {imageUrl ? (
              // <object data={chatObj.chat.filename} width="100%" height="100%"></object>
              <object data={imageUrl} width="100%" height="100%"></object>
          ):(
              <Box>Url이 없습니다</Box>
          )} */}
          {imagePaths.length > 0 ? (
            imagePaths.map((path: any, index: any) => (
              <img key={index} src={path} alt={`Page ${index + 1}`} style={{ width: '100%', marginBottom: '10px' }} />
            ))
          ) : (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}>
              <CircularProgress 
                  color="inherit" 
                  size={40}
                  sx={{
                      marginBottom: '0.5rem',
                  }}
              />
            </Box>
          )}
          </Box>
      </Stack>
    </>
  )
}

export default ChatFileViewerMobile;


// import { Box, Stack } from '@mui/material'
// import { useEffect, useState } from 'react';
// import { API_URL } from '../../../constants/urls';

// interface ChatFileViewerProps {
//     imageUrl: string | undefined;
//   }
  
// const ChatFileViewerMobile = ({imageUrl}: ChatFileViewerProps) => {
//   const [htmlContent, setHtmlContent] = useState('');

//   const handleConvert = async (imageUrl: string) => {
//     if (!imageUrl) {
//       alert('Please enter an S3 URL');
//       return;
//     }

//     try {
//       // 서버에 S3 URL을 전달하여 변환 요청
//       const response = await fetch(`${API_URL}/pdf/convert`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ s3Url: imageUrl }),
//         // headers: headers, // 헤더 추가
//         credentials: "include", // jwt 토큰을 전달하기 위한 설정
//       })
//       const arrayBuffer = await response.arrayBuffer();
//       const htmlContent = new TextDecoder('utf-8').decode(new Uint8Array(arrayBuffer));
//       setHtmlContent(htmlContent);
//     } catch (error) {
//       console.error('Error converting file:', error);
//     }
//     };
//   useEffect(() => {
//     if (imageUrl) {
//       handleConvert(imageUrl);
//     }
//   }, [imageUrl]); // s3Url이 변경될 때마다 실행

//   return (
//     <>
//       {/* PdfViewer */} 
//       <Stack sx={{width: '100%', borderRight: '1px solid black' }}>
//           <Box sx={{ height: '100vh', width: '100%', overflow: 'auto' }}>
//               {imageUrl ? (
//                   // <object data={chatObj.chat.filename} width="100%" height="100%"></object>
//                   <object data={imageUrl} width="100%" height="100%"></object>
//                   //<div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
//               ):(
//                   <Box>Url이 없습니다</Box>
//               )}
//           </Box>
//       </Stack>
//     </>
//    )
//   }


// export default ChatFileViewerMobile;


// import React, { useState } from 'react';
// import axios from 'axios';

// const App() {
//   const [s3Url, setS3Url] = useState('');
//   const [htmlContent, setHtmlContent] = useState('');

//   const handleUrlChange = (event) => {
//     setS3Url(event.target.value);
//   };

//   const handleConvert = async () => {
//     if (!s3Url) {
//       alert('Please enter an S3 URL');
//       return;
//     }

//     try {
//       // 서버에 S3 URL을 전달하여 변환 요청
//       const response = await axios.post('http://localhost:3000/pdf/convert', { s3Url }, {
//         responseType: 'arraybuffer',
//       });

//       const htmlContent = new TextDecoder('utf-8').decode(new Uint8Array(response.data));
//       setHtmlContent(htmlContent);
//     } catch (error) {
//       console.error('Error converting file:', error);
//     }
//   };

//   return (
//     <div className="App">
//       <h1>PDF to HTML Converter</h1>
//       <input
//         type="text"
//         value={s3Url}
//         onChange={handleUrlChange}
//         placeholder="Enter S3 URL"
//       />
//       <button onClick={handleConvert}>Convert</button>
//       <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
//     </div>
//   );
// }

// export default App;