import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useState } from "react";
import { router } from "../../Routes";
import LoadingModal from "../../../util/LoadingModel";
import { useHandleFileUpload } from "../../../hook/useHandleFileAwsUpload";
import { snackVar } from "../../../constants/snackVar";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export const SmText = styled(Typography)({
    color: 'black',
    fontWeight: 600,
    marginBottom: '20px',
    fontFamily: 'Poppins',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

interface ChatCreateModalProps {
    open: boolean;
    handleClose: () => void;
}

const ChatCreateModal = ({ open, handleClose }: ChatCreateModalProps) => {
    const [loading, setLoading] = useState({ active: false, message: '', processNumber: '0/0' }); // 로딩 상태 관리
    const { handleFileAwsUpload } = useHandleFileUpload();

    const handleFileUpload = async (event: any) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        
        try { 
            const createChatRes = await handleFileAwsUpload(formData, setLoading);
            router.navigate(`/chats/${createChatRes?.data.createChat._id}`) 
            handleClose()
            setLoading({active: false, message: '', processNumber: '0'});
          } catch (error) {
            setLoading({active: false, message: '', processNumber: '0'});
            snackVar({message: '파일 업로드 체크', type: 'error'})
            //handleClose()
            throw new Error('Unknown Error')
          }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            {loading.active ? (
                <>
                    <LoadingModal open={loading.active} message={loading.message}/>
                </>
            ) : (
              <Box
                sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '70%',
                    bgcolor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 24,
                    p: 4,
                    justifyContent: 'space-between',
                    height: {
                        sm: '200px', // 'md' 사이즈 이하에서 height는 70%
                        xs: '220px' // 기본값은 32%
                      },        
                }}
            >   
                <Stack sx={{ marginTop: '20px' }}> 
                    <SmText>Upload your PDF</SmText>
                    <Button 
                        component="label"
                        role={undefined}
                        startIcon={<AddOutlinedIcon sx={{ justifyContent: 'center'}}/>} 
                        sx={{
                            height: '50px',         
                            borderColor: 'black', // 외곽선 색상 지정
                            color: 'black', // 버튼 텍스트 색상도 변경하고 싶다면}}
                            '&:hover': {
                                borderColor: 'white', // 호버 시 외곽선 색상 변경
                                backgroundColor: '#F7F7F7', // 호버 시 배경색도 조정 가능
                                color: '#B2B2B2' // 버튼 텍스트 색상도 변경하고 싶다면
                            } 
                        }}
                        variant='outlined'
                    >
                        <VisuallyHiddenInput 
                            type="file" 
                            accept="application/pdf"
                            onChange={handleFileUpload}
                        />
                    </Button>
                </Stack>
            </Box>
            )}
        </Modal>
    );
}

export default ChatCreateModal;
