import { useEffect } from "react";
import { router } from "../Routes";
import { snackVar } from "../../constants/snackVar";
import usePath from "../../hook/usePath";
import { useGetUser } from "../../hook/useGetUser";

interface GuardProps {
    children: JSX.Element
}

export const excludedRoutes = ["/uploads"]

const Guard = ({ children }: GuardProps) => {
    const { data: user, error } = useGetUser();
    const { path } = usePath() //window.location.pathname 값이 라우터 상태와 window.location 동기화 문제가 일어날 수 있어 usePath 훅을 사용하여 강제적으로 호출하면 location이 업데이트 된다.

    useEffect(() => {
        if (error) {
            if (error.networkError) {
                snackVar({ message: "네트워크 에러가 발생했습니다.", type: 'error' })
            } else if (!excludedRoutes.includes(path)) {
                snackVar({ message: error.message, type: 'error' })
            } else {
                snackVar({ message: error.message, type: 'error' })
            }
            router.navigate('/uploads')
        }
    }, [error])

    return (
        <>
            {
                excludedRoutes.includes(path)
                ? children
                : user && children
            }
        </>
    );
};

export default Guard;