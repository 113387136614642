import { snackVar } from "../constants/snackVar"
import { API_URL } from "../constants/urls";

interface VectorRes {
    namespace: string,
    index: string,
    originFileName: string, 
    filename: string,
}

const useUploadVector = () => {
    // const token = getToken(); // 토큰을 가져옴
    // const headers = {
    //     'Authorization': token // 토큰을 'Authorization' 헤더에 추가
    // };
    const uploadVector = async(formData: FormData) => {
        const vectorRes = await fetch(`${API_URL}/upload`, {
            method: 'POST',
            body: formData,
            // headers: headers, // 헤더 추가
            credentials: "include", // jwt 토큰을 전달하기 위한 설정
        })
        if (!vectorRes.ok) {
            throw new Error(vectorRes.statusText)
        }
        const vectorData: VectorRes = await vectorRes.json()
        return vectorData
    };
    return { uploadVector }
} //end of UploadFile

export { useUploadVector };