import { router } from "../component/Routes";
import client from "../constants/apollo-client";
import { API_URL } from "../constants/urls";

const useRemoveToken = () => {
    const removeToken = async () => {
        const res = await fetch(`${API_URL}/auth/logout`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!res.ok) {
            return new Error(res.statusText);
        }
        client.resetStore();
        router.navigate('/uploads');
    }
    return { removeToken }
}


export { useRemoveToken } 