import { createBrowserRouter } from "react-router-dom";
import Chat from "./chat/detail/Chat";
import ChatList from "./chat/list/ChatList";
import Upload from "./upload/Upload";
import { Test } from "./Test";


export const router = createBrowserRouter([
    {
        path: '/',
        element: <ChatList />
    },
    {
        path: '/uploads',
        element: <Upload />
    },
    {
        path: '/chats/:id',
        element: <Chat />
    },
    {
        path: '/test',
        element: <Test />
    },
])