import { Box, IconButton, Stack, TextField } from "@mui/material";
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { snackVar } from "../../../constants/snackVar";
import CircularProgress from '@mui/material/CircularProgress';
import { useMessageCreated } from "../../../hook/useMessageCreated";
import { useCreateMessage } from "../../../hook/useCreateMessage";

interface ChatInputProps {
    chatId: string;
  }

const ChatInput = ({ chatId }: ChatInputProps) => {
    const params = useParams();
    const paramChatId = params.id!;

    if (paramChatId !== chatId) {
        snackVar({ message: 'Chat Error', type: 'error' });
        new Error('Chat Error');
    }
    
    const [createMessage] = useCreateMessage(chatId);
    const [message, setMessage] = useState('');

    //subscription
    const [loading, setLoading] = useState(false); // 로딩 상태 관리

    const { data: subscriptionMessage } = useMessageCreated(chatId);

    useEffect(() => {
        if (subscriptionMessage) {
            setLoading(false);            
        }
    }, [subscriptionMessage]);

    return (
        <Stack>
            {/* TextInput */}
            <Box sx={{ p: 1, borderTop: '1px solid black', backgroundColor: 'white', display: 'flex'}}>
                {/* Input Field */}
                <Box sx={{ width: '95%' }}>
                <TextField
                    fullWidth
                    multiline
                    placeholder="message..."
                    variant="outlined"
                    minRows={1}
                    maxRows={3} // Adjust maxRows as needed
                    InputProps={{
                        sx: {
                            height: '4.0rem', // Sets the height of the Input
                            padding: '0px 12px', // Reduces padding, adjust the numbers as needed
                            // You can also target the input element itself for further size adjustments
                            '& input': {
                                fontSize: '0.85rem', // 0.9rem으로 할당하면 폰트가 작아서 모바일 화면에서 TextField를 클릭시 확대됨
                                padding: '0.5px', // Reduces vertical padding, making the overall TextField shorter
                                '@media (max-width: 600px)': {
                                    fontSize: '16px', // Increases font size on mobile to prevent zoom
                                },
                            },
                            width: '100%',
                        },
                    }}
                    sx={{
                        p: 0,
                        '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none', // Remove the outline border
                        },
                        '& .MuiOutlinedInput-input': {
                            fontSize: '0.85rem', // Smaller font size for input text
                            '@media (max-width: 600px)': {
                                fontSize: '16px', // Increases font size on mobile to prevent zoom
                            },
                        },
                        '& .MuiOutlinedInput-root': {
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Ensure the outline doesn't reappear on focus
                            },
                        },
                        },
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    onKeyDown={async (e) => {
                        if (loading) {
                            snackVar({ message: '현재 통신 중 입니다. 메세지를 발송할 수 없습니다.', type: 'error' });
                            return;
                        }
                        if (message.trim().length <= 0) {
                            setMessage('')
                            return;
                        }
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();  
                            setLoading(true);
                            await createMessage({
                                    variables: {
                                        createMessageInput: {
                                            chatId: chatId,
                                            content: message,
                                            userType: 'USER'
                                        }
                                    }
                                });
                            setMessage('')
                        }
                        }}
                />
                </Box>
                {loading ? (
                    <Box>
                        {/* Input Button */}
                        <CircularProgress 
                            color="inherit" 
                            size={30}
                            sx={{ 
                                marginTop: '0.9rem',
                                marginRight: '0.5rem',
                            }}
                        />
                    </Box>
                    ) : (
                    <Box>
                        {/* Input Button */}
                        <IconButton
                            color="primary"
                            sx={{ 
                                marginTop: '1rem',
                            }}
                            onClick={async() => {
                                try { 
                                    if (message.trim().length <= 0) {
                                        setMessage('')
                                        return;
                                    }
                                    await createMessage({
                                        variables: {
                                            createMessageInput: {
                                                chatId: chatId,
                                                content: message,
                                                userType: 'USER'
                                            }
                                        }
                                    });
                                    setMessage('')
                                } catch (error) { 
                                    snackVar({ message: 'Error sending message', type: 'error' });
                                }
                            }}
                        >
                            <ElectricBoltOutlinedIcon 
                                sx={{ 
                                    color: 'black',
                                    width: '1.2rem',
                                    height: '1.2rem',
                                }}
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Stack>
    );
}
export default ChatInput;