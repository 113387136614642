import { ApolloProvider } from "@apollo/client";
import client from "./constants/apollo-client";
import { CssBaseline, Grid } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { router } from "./component/Routes";
import { CustomAlert } from "./constants/alert";
import Guard from "./component/guard/Guard";
import ChatList from "./component/chat/list/ChatList";
import usePath from "./hook/usePath";
import { createGlobalStyle } from 'styled-components';
import { useCallback, useEffect } from "react";
import { useTokenTimeCheck } from "./hook/useTokenTimeCheck";
import { snackVar } from "./constants/snackVar";

// /styled-components의 createGlobalStyle을 사용하여 :root에 CSS 변수 --vh를 정의합니다. 
//이 변수는 App 컴포넌트에서 계산되며, 화면의 크기가 변경될 때마다 업데이트됩니다.
const GlobalStyle = createGlobalStyle`
  :root {
    --vh: 100%;
  }
`;

const App = () => {
  const { path } = usePath();
  const showChatList = path.includes('/chats');

  const { tokenTimeCheck } = useTokenTimeCheck()
  useEffect(() => {
      // Define an async function inside useEffect
      const fetchTokenTime = async () => {
          try {
              const res = await tokenTimeCheck();  // Assuming this fetches token time data
              // if (res) {
              //   snackVar({message: `${res.days}일 ${res.hours}시간 ${res.minutes}분 이후 데이터가 삭제됩니다.`, type: 'info'})
              // } else if (res.hours) {
              //   snackVar({message: `${res.hours}시간 ${res.minutes}분 이후 데이터가 삭제됩니다.`, type: 'info'})
              // } else if (res.minutes) {
              //   snackVar({message: `${res.minutes}분 ${res.seconds}초 이후 데이터가 삭제됩니다. 토큰을 업데이트 해주세요`, type: 'info'})
              // } else if (res.seconds) {
              //   snackVar({message: `${res.seconds}초 이후 곧 데이터가 삭제됩니다. 토큰을 업데이트 해주세요`, type: 'error'})
              // }
              if (res.days === 0) {
                snackVar({message: `${res.days}일 ${res.hours}시간 ${res.minutes}분 이후 데이터가 삭제됩니다.`, type: 'info'})
              }
          } catch (error) {
              // Handle any errors
              console.error("Failed to fetch token time:", error);
              snackVar({message: `${error}`, type: 'error'})
          }
      };
      fetchTokenTime();
  }, []); 

  useEffect(() => {
    const setScreenSize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setScreenSize();
    window.addEventListener('resize', setScreenSize);

    return () => window.removeEventListener('resize', setScreenSize);
  }, []);

  return (
    <ApolloProvider client={client}>
      <CssBaseline />
        <Guard>
            { showChatList ? (
              <Grid container sx={{ position: 'fixed' }}>
                <Grid item xs={0} sm={2} md={2} xl={2} sx={{display: { xs: 'none', sm: 'flex' }}}>
                  <ChatList />
                </Grid>
                <Grid item xs={12} sm={10} md={10} xl={10}> 
                  <Routes />
                </Grid>
              </Grid>
            ) : ( 
              <Routes />
            )
            }
        </Guard>
        <CustomAlert />
    </ApolloProvider>
  );
}

const Routes = () => {
  return (
      <RouterProvider router={router} />
  )
}

export default App;
