import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { API_URL, WS_URL } from "./urls";

/*
* 쿼리와 뮤테이션을 처리를 위한 Http연결 클라이언트 생성
*/
const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`,
    credentials: 'include',
})

/*
* 구독을 위한 웹소켓 클라이언트를 생성.
*/
const wsLink = new GraphQLWsLink(
    createClient({
      url: `${WS_URL}/graphql`,
      shouldRetry: () => true, // 재시도 여부를 결정하는 함수.
    }),
);

/**
* Query-Mutation | Subscription를 구분하는 함수를 생성
* 네트워크 요청을 두 개의 다른 네트워크 링크(httpLink와 wsLink) 중 하나로 분기하는 역할
** 
* [definition.kind] > GraphQL의 표준 연산(쿼리, 뮤테이션, 구독) 중 하나라는 것을 확인하는 절차
* [definition.operation] > 구독 요청인지 확인
**
* 구독요청인 경우 -> wsLink로 연결
* 뮤테이션 또는 쿼리인 경우 -> httpLink로 연결
* 
*/
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' && 
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
)

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    credentials: 'include',
});

export default client;