import React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useReactiveVar } from '@apollo/client';
import { snackVar } from './snackVar';

export const CustomAlert = () => {
  const snack = useReactiveVar(snackVar);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return; // Prevents the snackbar from closing when clicking outside of it
    }
    snackVar(undefined);
  };

  return (
    <>
      <Snackbar
        open={snack !== undefined}
        autoHideDuration={3000} // 3 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioned at the top right
      >
        <Alert onClose={handleClose} severity={snack?.type} sx={{ width: '100%' }}>
        {/* <AlertTitle>Info</AlertTitle> */}
          {snack?.message}
        </Alert>
      </Snackbar>
    </>
  );
}