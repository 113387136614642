import { Avatar, Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatFileViewer from './ChatFileViewer';
import { useGetChat } from '../../../hook/useGetChat';
import { useGetMessage } from '../../../hook/useGetMessage';
import usePath from '../../../hook/usePath';
import { router } from '../../Routes';
import { useChatMatch } from '../../../hook/useChatMatch';
import { useParams } from 'react-router-dom';

// interface Message {
//     _id: string;
//     chatId: string;
//     content: string;
//     createdAt: Date;
//     userId: string;
//     userType: string;
// }

const Chat = () => {
  //const userId = '-->>> subsctiption Check -->>>';
  //const { data: createdUser } = useSubscriptionUser(userId);
  const { path } = usePath();
  const pathSplit = path.split("chats/");
  const chatId: string = pathSplit[1];
//   const params = useParams();
//   const chatId: string = params.id!;
  const { data: chatObj } = useGetChat(chatId);
  const { error: chatMatchError } = useChatMatch(chatId);
  const { data: messagesObj } = useGetMessage(chatId);
  
  //chatId가 유저의 chatId와 일치하는지 확인
  useEffect(() => {
    if (chatMatchError) {
      router.navigate('/');
    }
  }, [chatMatchError]);
  
  const divRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    scrollToBottom();
  }, [messagesObj?.messages.length])

  return (
    <>
    {/* Container Stack */}
    <Stack 
     direction="row"
     sx={{ width: '100%', height: 'calc(var(--vh, 1vh) * 100)', border: '1px solid black', borderLeft: 'none', justifyContent: 'space-between', display: 'flex'}}
    >
        <ChatFileViewer imageUrl={chatObj?.chat.imageUrl} /> 
        {/* <ChatFileViewer imageUrl="https://rag-pdf-bk.s3.ap-northeast-2.amazonaws.com/file/8a359379-f6f9-495a-a287-8b764b832a11END-START03fa87ea-0c82-4270-819f-c0a9c6fadd9dEND-START72a73010-c8bc-4757-a39d-b25b35432072END-STARTsample%2B(1)+(2).pdf" />* /}
        
        {/* ChatMessageArea */}       
        <Stack sx={{ width: '100%' }}>
            {/* ChatHeader */}
            <ChatHeader chatId={chatId} />
            <Box sx={{ height: '100%', overflow: 'auto' }}>
            {messagesObj?.messages.map((message: any, index: any) => (
                <React.Fragment key={index}>
                    {message.userType === 'AI' ? (
                        <Stack 
                            direction="row" 
                            spacing={2} 
                            sx={{ p:1.5, mb: 0, alignItems: 'top', maxWidth: '85%'}}  
                        >
                            <Avatar 
                                src="https://rag-pdf-bk.s3.ap-northeast-2.amazonaws.com/constantImg/painter-4856002_1280.jpg" 
                                sx={{ width: '25px', height: '25px'}} 
                            />
                            {/* Message */}
                            <Typography sx={{ bgcolor: 'grey.100', p: 1.5, borderRadius: 2, fontSize: '0.8rem'}}>
                                {/* { currentMessage ? message.content && <TypingEffect message={currentMessage} speed={50} /> : <>{message.content}</>} */}
                                {message.content}
                            </Typography>
                        </Stack>
                    ) : message.userType === 'USER' ? (                   
                        <Stack 
                            direction="row" 
                            justifyContent="flex-end" 
                            spacing={2} 
                            sx={{ p:1, marginLeft: 'auto', alignItems: 'center', maxWidth: '80%', mb: 0}}
                        >
                            {/* Response Message */}
                            <Typography sx={{ p: 1.5, borderRadius: 2, fontSize: '0.8rem', borderColor: 'grey.200', borderWidth: 1, borderStyle: 'solid'}}>
                                {message.content}
                            </Typography>
                        </Stack>
                    ) : null}
                </React.Fragment>
            ))}
            <div ref={divRef}></div>
            </Box>
            {/* ChatInput */}
            <ChatInput chatId={chatId} />
        </Stack>
    </Stack>
    </>
); //end of return
};

export default Chat;




