import { Box, Divider, Drawer, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useEffect, useState } from "react";
import { router } from "../../Routes";
import ChatList from "../list/ChatList";
import ChatFileViewerMobile from "./ChatFileViewerMobile";
import styled from "@emotion/styled";
import usePath from "../../../hook/usePath";
import { useGetChat } from "../../../hook/useGetChat";
import { useRemoveChat } from "../../../hook/useRemoveChat";
import { useRemoveToken } from "../../../hook/useRemoveToken";
import { useTokenUpdate } from "../../../hook/useTokenUpdate";
import { API_URL } from "../../../constants/urls";

import { useGetMessage } from "../../../hook/useGetMessage";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface ChatHeaderProps {
    chatId: string;
}

export const SmallText = styled(Typography)({
    color: 'black',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const ChatHeader = ({ chatId } : ChatHeaderProps) => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [imagePaths, setImagePaths] = useState<string[]>([]);
    const { data: messagesObj } = useGetMessage(chatId);

    const handleConvert = async (imageUrl: string, chatId: string) => {
      try {
        // 서버에 S3 URL을 전달하여 변환 요청
        const response = await fetch(`${API_URL}/pdf/convert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ s3Url: imageUrl, chatId: chatId }),
          // headers: headers, // 헤더 추가
          credentials: "include",
        })
              if (!response.ok) {
        throw new Error('Failed to convert PDF');
      }
      const data = await response.json();
      setImagePaths(data.imagePaths);

      } catch (error) {
        console.error('Error converting file:', error);
      }
      };

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
      };
    const toggleDrawerFile = (newOpen: boolean) => () => {
        if (newOpen === true) handleConvert(data?.chat.imageUrl, chatId)
        if (newOpen === false) setImagePaths([])
        setFileOpen(newOpen);
    };
    const [open, setOpen] = useState(false);
    const [fileOpen, setFileOpen] = useState(false);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };  
    
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };    
    
    const { data } = useGetChat(chatId);
    const [removeChat] = useRemoveChat(chatId);
    const { removeToken } = useRemoveToken();
    const { tokenUpdate } = useTokenUpdate();

    const handleRemoveChat = async () => {
        try {
            await removeChat({
                variables: { chatId }
            });
            handleCloseUserMenu();
            router.navigate(`/`)
        } catch (err) {
            console.error("Error removing chat:", err);
        }
    };

    const handleRemoveToken = async () => {
        try {
            await removeToken();
        } catch (err) {
            console.error("Error removing chat:", err);
        }
    };

    const handleTokenUpdate = async () => {
        try {
            await tokenUpdate();
        } catch (err) {
            console.error("Error removing chat:", err);
        }
    };

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        messagesObj?.messages.forEach((message: any, index: number) => {
            doc.text(message.content, 10, 10 + (index * 10));
        });
        doc.save(`chat-${chatId}.pdf`);
    };

    // const handleDownloadPdf = async () => {
    //     const doc = new jsPDF('p', 'pt', 'a4');
    //     const content = chatRef.current;

    //     if (content) {
    //         const canvas = await html2canvas(content);
    //         const imgData = canvas.toDataURL('image/png');
    //         const imgProps = doc.getImageProperties(imgData);
    //         const pdfWidth = doc.internal.pageSize.getWidth();
    //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            
    //         // Add the image to the PDF
    //         doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    //         // Set font for Korean characters
    //         const fontBase64 = 'YOUR_FONT_BASE64_STRING'; // Replace this with your base64 font string
    //         doc.addFileToVFS('NotoSansKR-Regular.ttf', fontBase64);
    //         doc.addFont('NotoSansKR-Regular.ttf', 'NotoSansKR', 'normal');
    //         doc.setFont('NotoSansKR');

    //         // Add messages to the PDF
    //         let yOffset = pdfHeight + 10;
    //         messagesObj?.messages.forEach((message: any, index: number) => {
    //             const utf8Message = Buffer.from(message.content, 'ascii').toString('utf8');
    //             const textLines = doc.splitTextToSize(utf8Message, pdfWidth - 20);
    //             textLines.forEach((line: string) => {
    //                 if (yOffset + 10 > doc.internal.pageSize.getHeight()) {
    //                     doc.addPage();
    //                     yOffset = 10;
    //                 }
    //                 doc.text(line, 10, yOffset);
    //                 yOffset += 10;
    //             });
    //         });

    //         doc.save('chat.pdf');
    //     }
    // };

    const { path } = usePath();

    useEffect(() => {
        setOpen(false) 
    }, [path]);

    return (
        <Stack 
           sx={{ height: '52px', display: 'flex', alignItems: 'flex-end', borderBottom: '1px solid black', backgroundColor: 'white'}}
        >
            {/* chatHeader */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
                {/* response header */}
                <Box sx={{ display: { sm: 'none', md: 'none', xl: 'none', marginLeft: '10px', marginTop: '2px'}}}>
                    <IconButton onClick={toggleDrawer(true)}>
                        <img src="https://rag-pdf-bk.s3.ap-northeast-2.amazonaws.com/constantImg/b+chat.svg" alt="Custom Icon" style={{ width: 20, height: 20 }} />
                    </IconButton>
                </Box>
                <Drawer 
                    open={open} 
                    onClose={toggleDrawer(false)}
                    sx={{
                        '& .MuiPaper-root': {
                          width: '60%',  // 너비를 240px로 설정
                        }
                      }}
                >
                  <ChatList />
                </Drawer>
                <Box sx={{ display: { sm: 'none', md: 'none', xl: 'none', marginTop: '2px' }}}>
                    <IconButton onClick={toggleDrawerFile(true)}>
                        <img src="https://rag-pdf-bk.s3.ap-northeast-2.amazonaws.com/constantImg/Out.svg" alt="Custom Icon" style={{ width: 20, height: 20 }} />
                    </IconButton>
                </Box>
                <Drawer 
                    open={fileOpen} 
                    onClose={toggleDrawerFile(false)}
                    sx={{
                        '& .MuiPaper-root': {
                          width: '100%',  // 너비를 240px로 설정
                          overflow: 'hidden'
                        }
                      }}
                >
                    <Box sx={{ p:0.5, width: '100%', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid grey'}}>
                        <SmallText>{data?.chat.originFileName}</SmallText>
                        <IconButton onClick={toggleDrawerFile(false)}>
                            <img src="https://rag-pdf-bk.s3.ap-northeast-2.amazonaws.com/constantImg/Add.svg" alt="Custom Icon" style={{ width: 24, height: 24 }} />
                        </IconButton>
                    </Box>
                    {/* <ChatFileViewerMobile imageUrl={data?.chat.imageUrl} /> */}
                    <ChatFileViewerMobile imagePaths={imagePaths} />
                </Drawer>
                <Box sx={{ ml: 'auto' }}>
                    <IconButton  onClick={handleOpenUserMenu}>
                        <MoreHorizIcon />
                    </IconButton>
                </Box>
                <Menu
                    sx={{ 
                        mt: '10px'
                    }} 
                    id="menu-appbar" 
                    anchorEl={anchorElUser} 
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    keepMounted 
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem 
                        key='Save Pdf' 
                        onClick={handleDownloadPdf}
                        sx={{ 
                            color: 'black',
                        }}
                    >
                        <Typography 
                            textAlign="center" 
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            PDF 저장하기
                        </Typography>
                    </MenuItem>
                    <MenuItem 
                        key='Update Token' 
                        onClick={handleTokenUpdate}
                        sx={{ 
                            color: 'black',
                        }}
                    >
                        <Typography 
                            textAlign="center" 
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            토큰 업데이트
                        </Typography>
                    </MenuItem>
                    <MenuItem 
                        key='Delete Chat' 
                        onClick={handleRemoveChat}
                        sx={{ 
                            color: 'black',
                        }}
                    >
                        <Typography 
                            textAlign="center" 
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            채팅 나가기
                        </Typography>
                    </MenuItem>
                    <Divider/>
                    <MenuItem 
                        key='Delete Token' 
                        onClick={handleRemoveToken}
                        sx={{ 
                            color: 'black',
                        }}
                    >
                        <Typography 
                            textAlign="center" 
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            로그아웃
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>  
        </Stack>
    );
};

export default ChatHeader;