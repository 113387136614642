import { gql, useQuery } from '@apollo/client';

export interface UserResponse {
    _id: string;
}

const GET_USER = gql`
    query GetUser {
        user {
            _id
        }
    }
`

const useGetUser = () => {
    // 여기에서 반환 타입을 { user: UserResponse }로 지정
    return useQuery<{ user: UserResponse }>(GET_USER);
}

export { useGetUser }