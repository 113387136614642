import { Typography, styled, Button, Stack  } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useEffect, useState } from "react";
import { router } from '../Routes';
import LoadingModal from '../../util/LoadingModel';
import { useHandleFileUpload } from '../../hook/useHandleFileAwsUpload';
import { snackVar } from '../../constants/snackVar';
import { useGetChatList } from '../../hook/useGetChatList';
import { SubText } from '../upload/Upload';

export const Text = styled(Typography)({
  color: 'black',
  fontWeight: 600,
  marginBottom: '25px',
  fontFamily: 'Poppins',
  fontSize: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const LoadingText = styled(Typography)({
  color: 'black',
  fontWeight: 500,
  marginBottom: '12px',
  fontFamily: 'Poppins',
  fontSize: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  border: '0.5px solid black',
});


const Home = () => {
    const [loading, setLoading] = useState({ active: false, message: '', processNumber: '0/0' }); // 로딩 상태 관리
    const { handleFileAwsUpload } = useHandleFileUpload();
    const { data: chatList } = useGetChatList();
    const [ chats, setChats ] = useState<any>();
    const [ open, setOpen ] = useState(false);
    useEffect(() => {
      if (chatList) {
        setChats(chatList?.chats.length);
      }
    }, [chatList]);

    const handleChatCheck = () => {
      if (chats > 5) {
        snackVar({message: `채팅은 5개까지 생성 가능합니다.`, type: 'error'})
        setOpen(false);
        return false;
      } else {
        setOpen(true);
      }
    }

    const handleFileUpload = async (event: any) => {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      
      try { 
          const createChatRes = await handleFileAwsUpload(formData, setLoading);
          router.navigate(`/chats/${createChatRes?.data.createChat._id}`) 
          setLoading({active: false, message: '', processNumber: '0'});
    
        } catch (error) {
          setLoading({active: false, message: '', processNumber: '0'});
          snackVar({message: '파일 업로드 체크.', type: 'error'})
          
          throw new Error('Unknown Error')
        }
  }

  return ( 
    <>
    {loading.active ? ( 
      <LoadingModal open={loading.active} message={loading.message}/>
    ) : (
     <Stack 
      sx={{
        height: '100vh', 
        width: '100%', 
        margin: 'auto', 
        justifyContent: 'center',
        border: '1px solid black', 
        alignItems: 'center',
    }}
    >           
      <Text>Please upload your PDF</Text> 
      <SubText>PDF 문서 5페이지 + 1MB 이내 파일만 업로드해 주세요 🌝</SubText>   
      <Button 
          component="label"
          role={undefined}
          sx={{
              padding: '10px 20px', /* 상하 10px, 좌우 20px의 여백 */
              borderBottom: '3px solid black', /* 버튼 아래쪽 검은색 테두리 */
              borderRight: '3px solid black', /* 버튼 오른쪽 검은색 테두리 */
              display: 'flex',
              height: '50px',
              width: '50px',         
              borderColor: 'black', // 외곽선 색상 지정
              color: 'black', // 버튼 텍스트 색상도 변경하고 싶다면}}
              '&:hover': {
                  borderColor: 'white', // 호버 시 외곽선 색상 변경
                  backgroundColor: '#F7F7F7', // 호버 시 배경색도 조정 가능
                  color: '#B2B2B2' // 버튼 텍스트 색상도 변경하고 싶다면
              } 
          }}
          variant='outlined'
          onClick={handleChatCheck}
      >
        <AddOutlinedIcon sx={{ justifyContent: 'center'}}/>
        { open && <> 
          <VisuallyHiddenInput 
              type="file" 
              accept="application/pdf"
              onChange={handleFileUpload}
          />
        </>
        }
        
        </Button>
    </Stack>
     )} 
    </>
); //end of return
}


export default Home;