import { API_URL } from "../constants/urls"

const useTokenTimeCheck = () => {
    const tokenTimeCheck = async() => {
        const res = await fetch(`${API_URL}/auth/token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!res.ok) {
            return new Error(res.statusText);
        }
        const data = await res.json();
        return data;
    }
    return { tokenTimeCheck }
}

export { useTokenTimeCheck }
