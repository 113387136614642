import { gql, useQuery } from "@apollo/client";

export const GET_CHAT = gql`
    query Chat($chatId: String!) {
       chat(chatId: $chatId) {
        _id
        userId
        originFileName
        filename
        imageUrl
        createdAt
        }
    }
`

const useGetChat = (chatId: string) => {
    return useQuery(GET_CHAT, { variables: { chatId: chatId }})
}

export { useGetChat }