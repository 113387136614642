import React, { useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useTokenTimeCheck } from '../hook/useTokenTimeCheck';

// import AlertTitle from '@mui/material/AlertTitle';

export const Test = () => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  // let type;
  // const handleClick = async () => {
  //   try {
  //     setMessage(data?.user._id || '');
  //     type = 'success';
  //   } catch (error) {
  //       type = 'error';
  //   }
  //   setOpen(true);
  // };

  let type;
  const { tokenTimeCheck } = useTokenTimeCheck()
  const handleClick = async () => {
    try {
      const res = await tokenTimeCheck();
      type = 'success';
      setMessage(res?.days);
    } catch (error) {
      console.log("error: ", error);
    }
    setOpen(true);
  };


  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return; // Prevents the snackbar from closing when clicking outside of it
    }
    setOpen(false);
    type = '';
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000} // 6 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioned at the top right
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {/* <AlertTitle>Info</AlertTitle> */}
          {message}
        </Alert>
      </Snackbar>
      <Button 
        onClick={handleClick}
        sx={{ 
            marginTop: '10rem',
            width: '100%',
            height: '100%',
            border: '1px solid #000',
            color: '#000',
        }}
      >
        Show Alert
      </Button>
    </>
  );
}