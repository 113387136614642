import { Typography, styled, Stack, Button, LinearProgress } from '@mui/material';
import { useCreateUser } from '../../hook/useCreateUser';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useEffect, useState } from 'react';
import { useGetUser } from '../../hook/useGetUser';
import { useNavigate } from 'react-router-dom';
import client from '../../constants/apollo-client';
import { useHandleFileUpload } from '../../hook/useHandleFileAwsUpload';

export const Text = styled(Typography)({
  color: 'black',
  fontWeight: 600,
  marginBottom: '25px',
  fontFamily: 'Poppins',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});


export const SubText = styled(Typography)({
  color: 'black',
  fontWeight: 500,
  marginBottom: '25px',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const UploadLoadingText = styled(Typography)({
  color: 'black',
  fontWeight: 500,
  marginBottom: '20px',
  fontFamily: 'Poppins',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  border: '0.5px solid black',
});

const Upload = () => {
  const { handleFileAwsUpload } = useHandleFileUpload();   
  const { createUser } = useCreateUser();
  const { data } = useGetUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ active: false, message: '', processNumber: '0/0' }); // 로딩 상태 관리

  const handleFileUpload = async (event: any) => {
    await createUser();

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    try{
      setLoading({active: true, message: '🦧 Creating account...', processNumber: ''});
      const createChatRes = await handleFileAwsUpload(formData, setLoading);
      await client.refetchQueries({ include: "active" });
      navigate(`/chats/${createChatRes?.data.createChat._id}`)

    } catch (error) {
      setLoading({active: false, message: '', processNumber: '0'});
      throw new Error('Unknown Error')
    }
  }

  useEffect(() => {
    if (data) {
        navigate('/');
    }
  }, [data, navigate])
  
  return (
    <>
    {loading.active ? ( 
      <Stack 
        spacing={3}
        sx={{height: '80vh', maxWidth: '50%', margin: 'auto', justifyContent: 'center'}}
      >    
        <UploadLoadingText>{loading.message}</UploadLoadingText>
        <LinearProgress color='inherit'/> 
      </Stack>
    ) : (
    <Stack 
      sx={{height: '80vh', maxWidth: '50%', margin: 'auto', justifyContent: 'center'}}
    >        
      <Text>Please upload your PDF</Text>
      <SubText>PDF 문서 5페이지 + 1MB 이내 파일만 업로드해 주세요 🌝</SubText>     
      <Button 
          component="label"
          role={undefined}
          sx={{
              padding: '10px 20px', /* 상하 10px, 좌우 20px의 여백 */
              borderBottom: '3px solid black', /* 버튼 아래쪽 검은색 테두리 */
              borderRight: '3px solid black', /* 버튼 오른쪽 검은색 테두리 */
              display: 'flex',
              height: '50px',         
              borderColor: 'black', // 외곽선 색상 지정
              color: 'black', // 버튼 텍스트 색상도 변경하고 싶다면}}
              '&:hover': {
                  borderColor: 'white', // 호버 시 외곽선 색상 변경
                  backgroundColor: '#F7F7F7', // 호버 시 배경색도 조정 가능
                  color: '#B2B2B2' // 버튼 텍스트 색상도 변경하고 싶다면
              } 
          }}
          variant='outlined'
          onClick={() => {}}
      >
        <AddOutlinedIcon sx={{ justifyContent: 'center'}}/>
        <VisuallyHiddenInput 
              type="file" 
              accept="application/pdf"
              onChange={handleFileUpload}
          />
        </Button>
    </Stack>
    )}
    </> 
  ); // end of return
}
export default Upload;
