import { API_URL } from "../constants/urls";


const useCreateUser = () => {
    const createUser = async() => { 
        const userRes = await fetch(`${API_URL}/user/create`, { //const res로 받지 않으면 cookie가 안받아짐
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: 'include',
        });
        if (!userRes.ok) {
            return new Error(userRes.statusText);
        }
    };
    return { createUser }
}
export { useCreateUser };


// import { gql, useMutation } from '@apollo/client';


// interface CreateUserDto {
//     createUserDto: {
//         ip: string
//     }
// }

// export interface UserResponse {
//     _id: string
// }

// const CREATE_USER = gql`
//     mutation CreateUser($createUserDto: CreateUserDto!) {
//         createUser(createUserDto: $createUserDto) {
//             _id
//         }
//     }
// `

// const useCreateUser = () => {
//     return useMutation<UserResponse, CreateUserDto>(CREATE_USER)
// }

// export { useCreateUser }