import { gql, useQuery } from "@apollo/client";

export const GET_CHAT_MATCH = gql`
    query ChatMatch($chatId: String!) {
       chatMatch(chatId: $chatId) {
        _id
        userId
        originFileName
        filename
        imageUrl
        createdAt
        }
    }
`

const useChatMatch = (chatId: string) => {
    return useQuery(GET_CHAT_MATCH, { variables: { chatId: chatId }})
}

export { useChatMatch }