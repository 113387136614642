import { snackVar } from "../constants/snackVar";
import { useCreateChat } from "./useCreateChat";
import { useUploadFile } from "./useUploadFile";
import { useUploadVector } from "./useUploadVector";

export const useHandleFileUpload = () => {
    const { uploadFile } = useUploadFile();
    const { uploadVector } = useUploadVector();
    const [ createChat ] = useCreateChat();  

    const handleFileAwsUpload = (async (formData: FormData, setLoading: any) => {
        setLoading({active: true, message: '🦄 Uploading File...', processNumber: '1/3'});
        
        try{ 
            const awsData = await uploadFile(formData);

            setLoading({active: true, message: '🐫 Convert Vector...', processNumber: '3/4'});
            const vectorData = await uploadVector(formData);
            
            setLoading({ active: true, message: '🐕 Completing Task...', processNumber: '3/3' });
            const chatRes = await createChat({ 
                variables: { 
                    createChatInput: { 
                        originFileName: vectorData?.originFileName, 
                        filename: vectorData?.filename, 
                        vectorNamespace: vectorData?.namespace,
                        targetId: 'GPT-3.5-turbo-mutdaai01',
                        imageUrl: awsData?.awsUrl
                    } 
                }
            });
            return chatRes;
        } catch (error) {
            snackVar({ message: `${error}`, type: 'error' });
        }
    });
    
    return { handleFileAwsUpload };
}