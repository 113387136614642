import { gql, useQuery } from "@apollo/client";

interface ChatResponse {
    _id: string;
    userId: string;
    originFileName: string;
    filename: string;
    createdAt: string;
}


const GET_CHAT_LIST = gql`
    query Chats {
        chats {
            _id
            userId
            originFileName
            filename
            createdAt
        }
    }
`

const useGetChatList = () => {
    return useQuery<{ chats: ChatResponse[] }>(GET_CHAT_LIST)
}

export { useGetChatList }